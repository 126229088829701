.user-profile-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    .content {
        display: flex;
        flex-direction: column;
        word-break: break-word;
        margin-left: 24px;
        h3 {
            margin: 0;
            font-size: 36px;
        }
        p {
            font-size: 16px;
        }
    }
    padding: 30px 10px;
}