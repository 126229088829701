/**
 * Created by gilangaramadan on 11/12/17.
 */

@import "../variables/variablesMain";

#content {
    // margin-left: 267px;
    //margin-left: 13%;
    margin-left: $sidebar-width;
    // width: 86%;
    padding: 80px 10px 0px 10px;
    // padding: 1em 2em;
    // float: left;

    &.chat-box-open {
        width: calc(100% - #{$chat-box-width} - #{$sidebar-width} - 20px); // 20px = 10px (margin between sidebar & data tab content) + 10px (margin between chatbox & data tab content) 
        &.no-side-bar {
            width: calc(100% - #{$chat-box-width} - 20px); // 20px = 10px (margin between sidebar & data tab content) + 10px (margin between chatbox & data tab content) 
        }
    }

    //@media screen and (max-width: 1615px) {
    //    margin-left: 200px;        
    //}

    transition: all $sidebar-collapse-transition-time;
    &.no-side-bar {
        margin-left: 0px;
    }

    margin-top: var(--warning-bar-height)
}
