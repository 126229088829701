/**
 * Created by gilangaramadan on 11/12/17.
 * Styling for global site of Goalie
 */
@import "../variables/variablesMain";
@import "../variables/fonts";
@import "../variables/colors";

*:not(i){  // skipping `i` in order not to (potentially) screw up font-awesome.
    font-family: 'Roboto', sans-serif;
}

html {
    font-size: $emSize;
}
body {
    background: linear-gradient(rgb(254, 246, 245) 26.21%, rgb(245, 242, 255) 100%) no-repeat fixed;
    color: $font-color;
    font-size: $fontSize;
}
