@import "../variables/colors";
%empty-extend {
    font-style: italic;
    font-weight: normal;
    color: #808080;
}
.network-invitation-card {
    width: 100%;
    border-radius: 6px;
    max-width: 400px;

    p {
        word-wrap: break-word;
    }
    
    /* Style for date and time of when the invitation was created  */
     .invitation-date {
        color: #808080;
    }
    
    
    > .content {
        padding-top: 10px;
        & p {
            font-size: 12px;
            font-weight: bold;
        }

        > .content {
            .actions {
                color: #ffffff;
                button {
                    margin: 5px;
                }
                .accept {
                    background-color: #39ca74;
                }
                .reject {
                    background-color: $pastel-red;
                }
            }
        }
    }

    > .header {
        background-color: $blue-grey;
        height: 100px;
        text-align: center;
        padding: 10px;

        & p {
            color: #FFFFFF;
            font-size: 12px;
        }
    }

    .name-card {
        min-height: 225px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        > .content {
            .avatar-container, .avatar-img {
                margin-top: -40px;
                margin-bottom: 15px;
            }
            .avatar-container {
                display: flex;
                justify-content: center;
            }
            .description {
                font-size: 12px;
                white-space: pre-wrap;
                word-wrap: break-word;
                overflow-x: hidden;
                overflow-y: auto;
                max-height: 120px;
                &.empty {
                    @extend %empty-extend;
                }
            }
        }
    }

    .profile-info {
        > .content {
            p {
                &.empty {
                    @extend %empty-extend;
                }
            }
        }
    }
}

/** Used if the invitation was sent by another therapist and not by a client */
.network-invitation-card.by-therapist {
    > .header {
        background-color: $sidebar;        
    }
}
