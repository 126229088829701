/**
 * Created by gilangaramadan on 22/01/18.
 */
@import "../variables/layout";
@import "../variables/variablesMain";

%chat-window-fixed-extend {
    position: fixed;
    right: 17px;
    bottom: 0px;
    width: #{$chat-box-width};
    min-width: 350px;
    z-index: 10;
}

.chat-toggle {
    height: 50px;
    background: #EDECFF;
    color: black;
    cursor: pointer;
    padding: 10px 10px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.25);
    display: flex;
    flex-direction: row;
    align-items: center;
    
    &.fixed {
        @extend %chat-window-fixed-extend;
    }
}

.chat-window {
    @extend %chat-window-fixed-extend;
    background: #f8f8f8;
    top: $chatwindow-marginTop;
    color: #FFF;
    height: calc(100% - #{$chatwindow-marginTop});
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.25);
    transition: all $chatbox-expand-transition-time;

    &.fullscreen-sidebar-collapse {
        width: $fullscreenWhenSidebarCollapsedWidth;
    }

    &.fullscreen {
        width: $fullscreenWhenSidebarFixedWidth;
    }

    .chat-body {
        color: #000;
        display: flex;
        flex-direction: column;
        height: 100%;
        min-height: 0;
        border-top: 2px solid #EDECFF;
        .chat-show-messages {
            background-color: #FAFAFF;
            overflow-y: auto;
            padding: 10px 12px 0px 12px;;
            flex: 15;
 
            .spinner-container {
                display: flex;
                flex-direction: row;
                justify-content: center;
                padding: 10px;
                margin: 5px auto 20px auto;
                .ui.loader {
                    position: inherit;
                    width: 0px;
                }
            }
            
        }

        .floating-button-scroll-down {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            position: absolute;
            bottom: 20px;
            right: 15px;
            background-color: #fff;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
            height: 40px;
            width: 40px;
            border-radius: 50%;
            .ui.label {
                position: absolute;
                top: -7px;
                left: -7px;
            }
            i {
                margin: 0px;
            }
        }
    }
}

