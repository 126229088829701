/**
 * Created by gilangaramadan on 29/01/18.
 */
@import "../variables/variablesMain";

.inputFile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}
