/**
 * Created by gilangaramadan on 11/12/17.
 * Custom sidebar
 */
@import "../variables/variablesMain";

$expandSidebarButtonSize: 46px;

.sidebar {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 70px;
    left: 0;
    bottom: 0;
    //width: 13%;
    background-color: $sidebar;
    z-index: 100;
    //min-width: 200px;
    //min-width: $sidebar-width;
    width: $sidebar-width;
    transition: all $sidebar-collapse-transition-time;
    margin-top: var(--warning-bar-height);
    .sidebar-item {
        .header-container {
            display: flex;
            align-items: center;
            margin-top: 5px;
            margin-bottom: 5px;
            .header-label {
                font-weight: bold;
                color: #dbdbdb;
                font-size: 14px;    
                margin-left: 25px;
            }
        }

        .header {
            font-weight: bold;
            color: #dbdbdb;
            font-size: 14px;
            margin: 10px 0px 25px 25px;
        }

        &.user-list-wrapper::-webkit-scrollbar-thumb {
            background-color: $black;
        }
    
        &.user-list-wrapper {
            overflow-y: auto;
            overflow-x: hidden;
            padding: 0px;
            cursor: pointer;
            scrollbar-color: $black $sidebar;

            .user-item {
                font-size: .9rem;
                color: #FFFFFF;
                opacity: 0.85;
                &:hover {
                    opacity: 1;
                }
                .user-wrapper {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    position: relative;
                    padding: 10px 0 0 10px;
                    line-height: 1.4rem;
                    overflow-wrap: break-word;
                    white-space: normal;
                    overflow-x: hidden;

                    .user-avatar {
                        min-width: 45px;
                        margin-left: 3px;
                    }
                    .user-content {
                        flex: 1;
                        max-width: 156px;
                        .user-content-wrapper {
                            display: flex;
                            flex-direction: row;
                            -webkit-box-orient: horizontal;
                            -webkit-box-direction: normal;
                            .user-sentence-left {
                                flex: 1;
                                overflow: hidden;
                                white-space: nowrap;
                                .user-name {
                                    // flex: 1;
                                    // height: 1rem;
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                    white-space: nowrap;
                                }
                                .date-message {
                                    color: rgba(255,255,255, .5);
                                }
                            }
                            .user-notification {
                                color: #637282;
                                padding-top: 8px;
                            }
                            
                            /** A tiny spinner on the red badge, displayed while the actual number of unread messages is being fetched */
                            .message-count-loader {                                
                                position: relative;
                                left: 5px;
                                top:4px;
                                
                                // override the default 'mini' size of the Loader from Semantic UI
                                width:10px;  
                                height:10px;
                            }                            
                        }
                    }
                }
            }
        }

        .search-box {
            width: 100%;
            margin-bottom: 10px;
            padding: 0px 8px;
            input {
                font-size: .9em;
                color: #FFF;
                padding-top: .7em;
                padding-bottom: .7em;
            }
            > i.icon {
                color: #FFF;
                margin-right: .7em;
            }
        }
    }
    &.collapse {
        margin-left: -$sidebar-width;
    }
}

.expand-sidebar-button {
    position: fixed;
    top: 82px;
    z-index: 10;
    left: 0px;
    background-color: #343744;
    border-radius: 0 50px 50px 0px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
    color: #dadada;
    width: $expandSidebarButtonSize;
    height: $expandSidebarButtonSize;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all $sidebar-collapse-transition-time;
    margin-top: var(--warning-bar-height);
    &.hidden {
        display: flex;
        left: (calc(#{$expandSidebarButtonSize} * -0.7));
    }
    &:hover {
        color: #ffffff; // make the icons in the expand-sidebar-button light when hovered    
    }
}
