.empty-state {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
    $text-opacity: 0.7;
    h2 {
        font-size: 28px;
        opacity: $text-opacity;
    }
    p {
        font-size: 16px;
        opacity: $text-opacity;
    }
}