/**
 * Created by gilangaramadan on 11/12/17.
 * Colors set palette
 */

// Nice calculator for calculating SASS colour
// http://razorltd.github.io/sasscolourfunctioncalculator/

$black: #000 !default;
$gray-darker: lighten($black, 13.3%) !default; // #222
$gray-dark: lighten($black, 17.6%) !default; // #2D2D2D
$gray: lighten($black, 33.5%) !default; // #555
$gray-light: lighten($black, 46.7%) !default; // #777
$gray-lighter: lighten($black, 93.5%) !default; // #eee

$brand-primary: #1E87F0 !default;
$brand-success: #5cb85c !default;
$brand-info: #5bc0de !default;
$brand-warning: #f0ad4e !default;
$brand-danger: #F1453D !default;

/**
 *  Nice Day styleguide colors
 *  @see https://app.zeplin.io/project/5a9d2a8fa2c33e5513f8d93d/styleguide
 */
$tomato: #f04f35;
$blush: #eba89c;
$faded-pink: #f0d0d7;
$blue-grey: #a0b8c2;
$cloudy-blue: #c0cad6;
$light-grey: #dbe9d8;
$slate-grey: #4d5163;
$pastel-red: #df665a;
$bluish-grey: #7a9c9f;
$charcoal-grey: #424347;
$slate: #436374;
$beige: #ecdcd4;
$light-blue-grey: #d9e8ec;
$pale-grey: #f9f6f7;

$main-menu-items: $slate-grey;
$sidebar: #343744;
$body-background-color: #eee;
$font-color: #404040;

/**
 * Colour values defined in Semantic UI (the list is incomplete as of 30-11-2018)
 */
$semantic-green: #21ba45;
$semantic-yellow: #fbbd08;
$semantic-red: #db2828;
$semantic-black: #1b1c1d;
$semantic-grey: #767676;

