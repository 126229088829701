/**
 * Created by choirul on 20/05/19.
 * ROM Tab (questionnaires)
 */

.questionnaire-sub-title {
  position: relative;
  display: flex;

  i {
    position: absolute;
    right: 0;
    top: -5px;
    margin: 0;
  }
}
