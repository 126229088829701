/**
 * Created by gilangaramadan on 29/01/18.
 */

.edit-image-wrapper {
    margin-top: 30px;
    text-align: center;
}

.edit-image-section {
    margin: 40px 20% 0px 20%;
    .edit-image-margin {
        margin-bottom: 24px;
    }
}
