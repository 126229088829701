
@mixin btn-background($color, $darken-percentage: 10%) {
    background-color: $color;
    &:hover {
        background-color: darken($color, $darken-percentage);
    }
}
/**
 * Include this on any SVG container to fill SVG color
 */
@mixin svgFill($fill) {
    svg {
        g {
            use {
                fill: $fill;
            }
            g {
                fill: $fill;
            }
        }
    }
}

/**
 * Determine avatar size
 */
@mixin avatar-size($size) {
    border-radius: $size / 2;
    width: $size;
    height: $size;
    min-width: $size;
    background-size: cover;
    background-position: center center;
    .avatar-dummy {
        display: none;
    }
    .avatar-online-status {
        display: block;
        position: absolute;
        bottom: 0px;
        right: 0px;
    }
}