/**
 * Created by leo on 8/07/19.
 */
@import "../variables/colors";

@keyframes color {
    0% {
        background-color: white;
    }
    50% {
        background-color: #dbe5ff;
    }
    100% {
        background-color: white;
    }
}
.img-responsive {
    max-width: 100%;
    height: auto;   
}