@import "../variables/colors";


/** Chat online status (presence) selector styles */

.dot{
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: inline-block;
    margin-right:20px;
    position: relative;
    top:6px;

    &.small{
        height: 12px;
        width: 12px;
        top:12px;
        left:-12px;        
        margin:0;
        border: .06em solid white!important;
    }
    
    &.red{
      background: $semantic-red;  
    }

    &.green{
        background: $semantic-green;        
    }

    &.yellow{
        background: $semantic-yellow;
    }

    &.black{
        background: $semantic-black;
    }

    &.grey{
        background: $semantic-grey;
    }

    &.gray{
        background: $semantic-grey;
    }
}

.presence-selector-line {
    padding: 10px 0 14px 0;
    margin: 0 -20px;
    padding-left: 20px;
    transition: 0.4s;
    opacity: 0.55;
    &:hover {
        background: #f2f2f2;
        opacity: 1;        
    }
    
    &.selected-presence {
        font-weight: bold;
        opacity: 1;
    }    
}

.clickable-area {
    cursor: pointer;
}
