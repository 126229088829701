@import "../variables/colors";

.feeling-selector {
    display: flex;
    flex-direction: row;
    min-height: 40px;
    position: relative;

    .feeling-selector-bar {
        flex: 4;
        background-color: #d8d8d8;

        &.selected {
            background-color: $blue-grey;
        }

        &.min-value {
            flex: 0.1;
        }
    }
    
    > .left {
        position: absolute;
        left: 10px;
        top: 10px;

        &.highlight {
            color: $font-color;
        }
    }
    > .right {
        position: absolute;
        right: 10px;
        top: 10px;
        &.highlight {
            color: $font-color;
        }
    }
    /**
     * Slider style
     */
    input[type=range] {
        // -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
        opacity: 0;
        position: absolute;
        height: 100%;
        width: 100%; /* Specific width is required for Firefox. */
        background: transparent; /* Otherwise white in Chrome */
        cursor: pointer;
        margin: 0px;
        z-index: 1;
        // &::-webkit-slider-thumb {
        //     // -webkit-appearance: none;
        //     cursor: pointer;
        // }
        // &:focus {
        //     outline: none;
        // }
        // &::-ms-track {
        //     width: 100%;
        //     background: transparent;
        //     border-color: transparent;
        //     color: transparent;
        // }
        &:disabled {
            cursor: auto;
        }
    }
    
}