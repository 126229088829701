@import "../variables/colors";


.event-detail-dialog {
    p {
        word-wrap: break-word;
        white-space: pre-line;
        font-size: 16px;
    }

    svg {
        height: 20px;
        width: 20px;
        &.canceled-event {
            g {
                use {
                    fill: red;
                }
                g {
                    fill: red;
                }
            }
        }
    }
}
