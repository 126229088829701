/**
 * Created by leo, 2019-May-23
 *
 *  Layout constants and variables used in more than one place.
 *   
 */
:root {
    // default set as zero, if it's not defined as css would break.
    // basically used on the element that directly below the top nav, and any element that has sticky or fixed property
    // content wrapper, sidebar, data tracker time-selector, chat-window.
    // being set programatically using javascript based on the length of the warning bar items.
    --warning-bar-height: 0px;
}
$sidebar-width: 200px;
$chat-box-width: 350px;
$sidebar-collapse-transition-time: 200ms;
$chatbox-expand-transition-time: 300ms;
$fullscreenWhenSidebarFixedWidth: calc(100% - 282px); // 282px = 34px + 248px (sidebar width size)
$fullscreenWhenSidebarCollapsedWidth: calc(100% - 80px); // 80px = 34px (two times value of the chat window right space) + 46px (sidebar collapsed width button)
$chatwindow-marginTop: calc(70px + var(--warning-bar-height));
