// #app {
//     background: #eee;
//     padding: 20px;
//     height: 100%;
//     // overflow: auto;
//     /* floating children fix */
// }
@import "../variables/variablesMain";

$border-color: lighten(#808080, 30%);

.tracking-data {
    padding-bottom: 56px;
}

.plan-event-form, .logger-config-form, .custom-repetition, .datetime-modal, .steps-config-modal {
    h2 {
        font-size: 18px;
        word-wrap: break-word;
        white-space: pre-line;
    }
}

.plan-event-form {
    #dropdown_repeat_option {
        height: 37px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #ddd;
        .menu.transition {
            width: 100%;
        }
    }
}

/** TOOLTIP **/

.tooltipWrapper {
  position: relative;
}
.tooltip {
    position: absolute;
    z-index: 11;
    display: inline-block;
    padding: 11px 24px 13px;

    background-color: #FFFFFF;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.35);

    text-align: left;
    color: #000;
    font-size: 12px;
    line-height: 16px;
    white-space: pre-line;

    transition: 0.2s linear opacity;
    transition-delay: 0;
    opacity: 1;

    &.hidden {
        display: inline-block;
        opacity: 0;
        transition-delay: 0.1s;
        user-select: none;
        z-index: -999;
    }
    .title {
        font-weight: bold;
        margin-bottom: 6px;
    }
    .content {
        font-style: italic;
    }
}

/** TIME RANGE VIEW TOP **/

.time-selector {
    padding-bottom: 0;
    overflow: inherit !important;
}

// .time-selector,
// .time-selector .buttons,
.time-selector .intervalView {
    overflow: hidden !important;
    /* floating children fix */
}

.time-selector .intervalView {
    margin-left: 30px;
    margin-right: 38px;
}

.time-selector .buttons {
    width: 100%;
    border-bottom: solid 1px $border-color;
    padding-bottom: 10px;
    .sense-button {
        min-width: 10px;
        @include btn-background(#ffffff, 5%);
        color: $font-color;
        box-shadow: none;
        padding: 10px;
    }
}

.time-selector {
    .buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        .month-dropdown {
            border-right: 1px solid $border-color;
            padding-right: 20px;
            margin-right: 20px;
            line-height: 25px;
            min-width: 170px;
            .ui.dropdown {
                .text {
                    font-weight: bold;
                    min-width: 110px;
                }
            }
        }
        .arrow {
            @include svgFill($font-color);
            padding: 0px;
            &.left {
                svg {
                    transform: rotate(90deg);
                }
            }
            &.right {
                svg {
                    transform: rotate(-90deg);
                }
            }
        }
    }
}

.time-selector a {
    float: left;
    margin: 5px;
    padding: 3px 5px;
    cursor: pointer;
    background: transparent;
    color: $font-color;
    &:hover {
        font-weight: bold;
    }
}

.time-selector.sticky {
    position: fixed;
    z-index: 5;
    top: calc(57px + var(--warning-bar-height));
}

.interval.day,
.interval.week {
    display: block;
    float: left;
    min-height: 1px;
}

.interval.day {
    width: 14.285714286%;
    /* 1/7 */
}

.interval.week {
    width: 25%;
}

.interval.highlight {
    background-color: #DEF0FF;
}

.dateBlock {
    margin-right: -1px;
    padding: 10px 0px;
}

.dateBlock.highlight {
    background: #DEF0FF;
}

.dateBlock.week {
    cursor: pointer;
}

.dateBlock.week:hover {
    background: #ddd;
}

.dateBlock h1 {
    width: 100%;
    text-align: center;
    margin: 0 auto;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    color: #404040;
}

.dateBlock h2 {
    text-align: center;
    color: #808080;
    font-size: 12px;
    line-height: 16px;
    font-weight: normal;
    margin: 0px;
}


/** EVENTS **/

.eventsView {
    &.scrollable {
        height: 400px;
        overflow-y: auto;
    }
}

.eventsView .content {
    overflow: auto;
    display: flex;
    margin-left: 30px;
    margin-right: 38px;
    height: 100%;
    width: 100%;
}

/** GRAPHS **/

.graph-container {
    .graph {
        width: 100%;
        svg {
            transition: opacity 0.5s ease-in-out;
        }
        .loading svg {
            opacity: .4;
        }
        .loader {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
        rect.highlight {
            fill: #DEF0FF;
        }
    }
}

.bar rect,
.bars rect {
    stroke: #aaa;
    fill: #ddd;
}

.line path {
    fill: none;
    stroke: #000;
}


/** AXIS **/

.axis line {
    fill: none;
    shape-rendering: crispEdges;
    //stroke-width: 22px; // experimenting with the axis width
    stroke: #aaa;
}

.y.axis line {
    stroke: #CECED2;
    //stroke: #ff0000; // experimenting with the axis colour
}

.x.axis line {
    stroke: #D8D8D8;
    //stroke: #0000ff; // experimenting with the axis colour
}

.y.axis .tick text {
    font-size: 15px;
}


/** STEP COUNT GRAPH **/

.ui.segment.step-count {
    margin-bottom: 75px;
}

.step-count .line path {
    stroke: #39ca74;
    stroke-width: 3px;
    stroke-dasharray: 6px 5px;
}

.step-count .bar rect {
    fill: #1E87F0;
    stroke: none;
}

.step-count .bar.success rect {
    fill: #29c566;
    stroke: none;
}


/** FEELINGS GRAPH **/

.feelings .line path {
    stroke: #Faa;
    stroke-width: 2px;
}


/** MOOD GRAPH **/

.mood .bar rect {
    fill: rgba(254, 183, 106, 0.5);
    stroke: #FFB76A;
}

.mood .line path {
    stroke: #F1453D;
    stroke-width: 2px;
}


/** FEELINGS **/

$happy: #2576b6;
$confident: #bcbf00;
$motivated: #2fbed0;
$relaxed: #24a121;
$energized: #fa7f00;
$sad: #7f7f7f;
$angry: #d8241e;
$fearful: #9464bf;
$tense: #b8a593;
$painful: #e574c3;

.feelings .bars .happy { fill: $happy; }
.feelings .bars .confident { fill: $confident; }
.feelings .bars .motivated { fill: $motivated; }
.feelings .bars .relaxed { fill: $relaxed; }
.feelings .bars .energized { fill: $energized; }
.feelings .bars .sad { fill: $sad; }
.feelings .bars .angry { fill: $angry; }
.feelings .bars .fearful { fill: $fearful; }
.feelings .bars .tense { fill: $tense; }
.feelings .bars .painful { fill: $painful; }

/** FEELINGS TOGGLES **/

.emotionPicker .add,
.emotionPicker .active {
    float: left;    
}

.emotionPicker .add {
    border-radius: 50px;//50%;
    padding: 5px 10px;
    margin-top: 5px;
}




.emotionPicker .tag {
    float: left;
    margin: 5px;
    padding: 3px 12px 2px;
    border-radius: 50px;
    background: #777;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);

    // cursor: pointer;

    
    color: #fff;
    font-size: 12px;
    font-weight: normal;
    text-transform: capitalize;

    display: flex;
    align-items: center;

    @include svgFill(#fff);
    svg {
        height: 16px;
        width: 16px;
        margin-left: 5px;
        cursor: pointer;
    }
}

.emotionPicker .tag .close {
    display: inline-block;
    background: #fff;
    border-radius: 50%;
    padding: 1px 4px 1px 4px;
    color: #777;
    margin: 1px 1px 2px 11px;
    font-size: 13px;
    font-weight: bold;
    line-height: 1;
}

.emotionPicker .tag.happy { background: $happy; }
.emotionPicker .tag.confident { background: $confident; }
.emotionPicker .tag.motivated { background: $motivated; }
.emotionPicker .tag.relaxed { background: $relaxed; }
.emotionPicker .tag.energized { background: $energized; }
.emotionPicker .tag.sad { background: $sad; }
.emotionPicker .tag.angry { background: $angry; }
.emotionPicker .tag.fearful { background: $fearful; }
.emotionPicker .tag.tense { background: $tense; }
.emotionPicker .tag.painful { background: $painful; }

.last-sync {
    font-size: 12px;
    color: #808080;
    margin-bottom: 10px;
}

.event-filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    .ui.dropdown {
        min-width: max-content;
        border-radius: 50px;
        padding: 5px 10px;
    }
    .active-filters {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        .filter {
            display: flex;
            align-items: center;
            background: #EEEEEE;
            margin: 5px;
            border-radius: 50px;
            padding: 3px 12px 2px;
            color: #212121;
            font-size: 12px;

            @include svgFill(#212121);
            svg {
                height: 16px;
                width: 16px;
                margin-left: 5px;
                
                @include svgFill(#212121);
                &.close {
                    margin-left: 8px;
                    cursor: pointer;
                }
            }
        }
    }
}
