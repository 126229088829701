/**
 * Created by gilangaramadan on 08/12/17.
 * Header element
 */

// Header with text below it
.ui.header {
    &.with-sub {
        margin-bottom: 0;
    }
}

.info-header {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 24px;
}

.info-text {
    font-size: 1.2rem;
    max-width: 650px;
}
