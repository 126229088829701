.clients-birthday-card {
    // based on zeplin https://zpl.io/brMK4GW
    min-width: 355px;
    max-width: 400px;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 0px;
    .clients-birthday-card-header {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        .clients-birthday-card-header-label {
            font-size: 18px;
            font-weight: 500;
            color: $font-color;
        }
        .clients-birthday-range {
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: flex-end;
            margin-left: 10px;
            .label {
                font-size: 12px;
                font-weight: bold;
                color: $font-color;
                text-decoration: underline;
                &:hover {
                    cursor: pointer;
                }
            }
            .label.inactive {
                opacity: 0.5;
            }
            .label.count {
                text-decoration: none;
            }
        }
    }
    .clients-birthday-card-detail {
        overflow-y: auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 10px;
        .clients-birthday-item{
            margin-right: 10px;
            .label {
                font-size: 12px;
                color: $font-color;
                text-decoration: underline;
                &:hover {
                    cursor: pointer;
                }
            }
            .label.no-underline {
                text-decoration: none;
            }
        }
        .empty-birthday-label {
            font-size: 12px;
            color: $font-color;
            font-style: italic;
        }
    }
}
