/**
 * Created by Leonid Boguslavski on 18/04/19.
 * Styles for the tabbed userlist
 */
@import "../variables/variablesMain";

.sidebar-item {
    &.userlist-container {
        //border: solid 1px red;
        margin-top: -26px;
        display: flex;
        flex-direction: column;
        flex: 1;
        min-height: 0; // Fix firefox not showing y-scroll
        .ui.pointing.secondary.menu {
            //border: solid 1px red;
            //max-width: $sidebar-width;
            overflow-x: hidden;
            overflow-y: hidden;
            display: flex;
            flex-direction: row;
            
            .item {
                color: rgba(255, 255, 255, 0.5); // deselected tab  text
                font-weight:normal;
                flex: 1;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                .userlist-tab-header {
                    font-size: 12px;
                }
                .ui.tiny.label.userlist-tab-label {
                    //margin-left: 0;
                    font-size: 10px; // a fall-back value for older browsers (just in case)    
                    //font-size: calc(8px + 0.1vw);
                }
                &.active {
                    color: #fff;
                    border-bottom: 3px solid #fff;      
                }
            }          
        }
    }
    .user-list-wrapper { //TODO: make all the styles named either `user-list-...` or `userlist-...`
        display: flex;
        flex-direction: column;
        flex: 1;
    }
}
