.clients-feed {
    .labels {
        .ui.label {
            border-radius: 32px;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
            background-color: #efeff4;
            color: #000000;
            font-size: 12px;
            margin-right: 8px;
            user-select: none;
            &:hover {
                cursor: pointer;
            }
            &.active {
                background-color: $blue-grey;
                color: #fff;
            }
        }
    }
    
    .clients-feed-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        margin-bottom: 10px;
        .header-left {
            display: flex;
            flex-direction: row;
            align-items: center;
            h1 {
                margin: 0px 10px 0px 0px;
                font-size: 18px;
            }
            .sense-button {
                min-width: 10px;
                @include btn-background(#ffffff, 5%);
                color: $font-color;
                box-shadow: none;
                padding: 10px;
            }
        }
        .header-right {
            display: flex;
            flex-direction: row;
            align-items: center;
            .hour-range {
                display: flex;
                flex-direction: row;
                align-items: center;
                i {
                    margin-right: 10px;
                }
                .ui.dropdown {
                    >.text {
                        font-size: 18px;
                    }
                }
            }
        }
    }

    .clients-feed-list {
        margin: 10px -14px;
        flex: 1;
        overflow-y: auto;
    }

    .clients-feed-footer {
        display: flex;
        flex-direction: row;

    }
    
}
.card-pagination {    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #393939;
    font-weight: 500;
    font-size: 14px;
    opacity: 0.5;
    width: 220px;
    
    .page-indication{
        display: flex;
        justify-content: center;
             
    }
    
    .navigation-arrows {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-left: 15px;
    }
}
