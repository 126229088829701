.UpdateEventOptionDialog {
    .UpdateEventOptionDialog__header {
        font-size: 20px;
    }
    .UpdateEventOptionDialog__options {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-top: 3em;
        .ui.radio.checkbox {
            margin: 1em 0;
        }
    }
}